// トラッキングタグAPI 仕様書
// Doc: https://confl.arms.dmm.com/pages/viewpage.action?pageId=201552262

import { Environment } from '@dmm-com/react-tracking-tag-api';

import { ChargeToretaLabel, EcommerceItem } from './type';

export const sendI3ToretaUseEvent = (
  emoneyTransactionId: string,
  i3ServiceCode: string,
) => {
  if (!window.i3 || i3ServiceCode === '' || emoneyTransactionId === '') {
    return;
  }

  type Method = 'showPoint';
  try {
    window.i3<Method>('create', 'showPoint');
    window.i3<Method>(`showPoint.set`, 'purchase', {
      purchase_id: emoneyTransactionId,
      purchase_id_type: 'use',
      paytype: 'pay-7',
    });
    window.i3<Method>(`showPoint.send`, 'show', 'point');
  } catch (e) {
    console.error(e);
  }
};

export const sendGtmEcommercePurchase = (
  id: string,
  price: number,
  items: EcommerceItem[],
) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer!.push({
    event: 'ecTrans',
    ecEvents: [],
    transactionId: id,
    transactionTotal: price,
    transactionProducts: items,
  });
};

export const sendToretaChargeEvent = (eventLabel: ChargeToretaLabel) => {
  if (!window.dataLayer) {
    return;
  }
  window.dataLayer!.push({
    event: 'dmmStdEvent',
    eventCategory: 'toreta_charge_keirin',
    eventAction: 'toreta_charge',
    eventLabel,
    nonInteraction: true,
  });
};

export const getEnvironment = (
  mode:
    | 'local-machine'
    | 'analyze'
    | 'development'
    | 'staging-s'
    | 'staging'
    | 'production',
): Environment => {
  if (mode === 'production') {
    return 'Production';
  }
  return 'Staging';
};
